import * as dateFns from "date-fns";
import { capitalize } from "lodash";

export default {
  applicantName: {
    formatDataKey: "Applicant Name",
    formatDataValue: (arg: string) => arg,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  agentCode: {
    formatDataKey: "Agent Code",
    formatDataValue: (arg: string) => arg,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  agencyName: {
    formatDataKey: "Agency Name",
    formatDataValue: (arg: string) => arg,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 350
  },
  personalLiabilityLimit: {
    formatDataKey: "Personal Liability Limit",
    formatDataValue: (arg: string) => arg.toString(),
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    colWidth: 230
  },
  createdOn: {
    formatDataKey: "Created",
    formatDataValue: (arg: string) =>
      dateFns.format(new Date(arg), "LLLL do, yyyy"),
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4,
    colWidth: 170
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => (arg ? capitalize(arg) : "N/A"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 5,
    colWidth: 100
  },
  printed: {
    formatDataKey: "Printed",
    formatDataValue: (arg: string) => (arg ? "Yes" : "No"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 6,
    colWidth: 100
  }
};
